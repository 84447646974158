import React from "react";
import {connect} from "react-redux"
import qs from "qs";


const Alert = ({alert}) => {
    const params = qs.parse(window.location.search.slice(1))
    if (params.failed)
        return (
            <div>
                <div className="alert alert-danger" role="alert">
                    Neispravni podaci
                </div>
            </div>
        );
    if (!alert || (!alert.message && !alert.error)) return null
    if (alert.error)
        return (
            <div>
                <div className="alert alert-danger" role="alert">
                    {alert.error}
                </div>
            </div>
        );
    if (alert.message)
        return (
            <div>
                <div className="alert alert-success" role="alert">
                    {alert.message}
                </div>
            </div>
        );
}

export default connect((state) => {
    return {alert: state.alert}
})(Alert);