import React from "react"
import InfoStatus from "../common/InfoStatus";
import UserGoStatus from "../common/UserGoStatus";
import Legend from "../common/Legend";
import CalendarView from "./CalendarView";

class ListAll extends React.Component {
    render() {
        return (
            <div>
                <InfoStatus/>
                <UserGoStatus/>
                <CalendarView/>
                <Legend/>
            </div>
        );
    }
}

export default ListAll;