import {FETCH_ACTIVE_USERS} from "../actions/types";

const usersReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_ACTIVE_USERS:
            return action.payload;
        default:
            return state;
    }
}

export default usersReducer;