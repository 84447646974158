import {FETCH_FUTURE_GO, OBRISI_GO, ODOBRI_GO} from "../actions/types";

const adminGoReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_FUTURE_GO:
            return action.payload;
        case ODOBRI_GO:
            return state.map((go) => {
                if (go.id !== action.payload.id) return go;
                return action.payload
            })
        case OBRISI_GO:
            return state.filter((go) => (go.id !== action.payload.id))
        default:
            return state;
    }
}

export default adminGoReducer;