import React from "react"
import InfoStatus from "../common/InfoStatus";
import Legend from "../common/Legend";
import UserGoStatus from "../common/UserGoStatus";
import CalendarPicker from "./CalendarPicker";
import NajavljeniGo from "./NajavljeniGo";

class Individual extends React.Component {
    render() {
        return (
            <div>
                <InfoStatus/>
                <UserGoStatus/>
                <CalendarPicker/>
                <NajavljeniGo />
                <Legend/>
            </div>
        );
    }
}

export default Individual;