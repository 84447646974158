import React from "react"
import {fetchActiveUsers, selectMonth} from "../../actions";
import {connect} from "react-redux"
import MonthSelector from "../common/MonthSelector";
import MyDate from "../../utils/MyDate";
import DateCell from "../common/DateCell";

class CalendarView extends React.Component {
    state = {selectedRow: null}

    componentDidMount() {
        this.props.fetchActiveUsers()
        this.props.selectMonth(MyDate.fromObject(this.props.calendar))
    }

    renderRows() {
        const date = MyDate.fromObject(this.props.calendar)
        if (!date) return null
        const daysInMonth = date.getDaysInMonth();
        if (this.props.users) {
            const monthDateArray = []
            for (let i = 1; i <= daysInMonth; i++) (
                monthDateArray.push(new MyDate(this.props.calendar.year, this.props.calendar.month, i))
            )
            return this.props.users.map((user) => this.renderRow(user, monthDateArray))
        } else
            return null
    }

    renderRow(user, monthDateArray) {
        const className = this.state.selectedRow === user.id ? "opaque" : ""
        return (
            <tr key={user.id} onMouseEnter={() => this.setState({selectedRow: user.id})}
                onMouseLeave={() => this.setState({selectedRow: null})} className={className}>
                <td className="sticky-col">{user.firstName} {user.lastName}</td>
                {
                    monthDateArray.map((date) => <DateCell
                        userId={user.id}
                        date={date}
                        key={`${user.id}.${date.getYear()}.${date.getMonth()}.${date.getDay()}`}
                    />)
                }
            </tr>
        );
    }

    render() {
        return (
            <>
                <MonthSelector wide={true}/>
                <div className="table-responsive">
                    <table className="table table-bordered table-dark table-sm text-center">
                        <tbody>
                        {this.renderRows()}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

export default connect((state) => {
    return {users: state.users, calendar: state.calendar, godisnji: state.godisnji}
}, {fetchActiveUsers, selectMonth})(CalendarView);

// {/*<tr>*/}
// {/*    <td>*/}
// {/*        <?php echo $currentUser['user_name'] . ' ' . $currentUser['user_lastname'] ?>*/}
// {/*    </td>*/}
// {/*    <?php for ($i = 1; $i <= 31; $i++): ?>*/}
// {/*    <?php if ($i > $daysInSelectedMonth): continue;?>*/}
// {/*    <?php else: $dayClass = $userClass->neradni->getDayClassNew($currentUser['user_id'], $selectedYear, $selectedMonth, $i);?>*/}
// {/*    <td className="<?=$dayClass?>">*/}
// {/*        <?=$i?>*/}
// {/*    </td>*/}
// {/*    <?php endif;?>*/}
// {/*    <?php endfor;?>*/}
// {/*</tr>*/}
// {/*<?php foreach ($users as $user):*/}
// {/*    if ($user['user_id'] == $user_id) {*/}
// {/*        continue;*/}
// {/*    } ?>*/}
// {/*<tr>*/}
// {/*    <td>*/}
// {/*        <?php echo $user['user_name'] . ' ' . $user['user_lastname'] ?>*/}
// {/*    </td>*/}
// {/*    <?php for ($i = 1; $i <= 31; $i++): ?>*/}
// {/*    <?php if ($i > $daysInSelectedMonth): continue;?>*/}
// {/*    <?php else: $dayClass = $userClass->neradni->getDayClassNew($user['user_id'], $selectedYear, $selectedMonth, $i);?>*/}
// {/*    <td className="<?=$dayClass?>">*/}
// {/*        <?=$i?>*/}
// {/*    </td>*/}
// {/*    <?php endif;?>*/}
// {/*    <?php endfor;?>*/}
// {/*</tr>*/}
// {/*<?php endforeach;?>*/}