import React from "react"
import {Route, Router, Switch} from "react-router-dom";
import Header from "./Header";
import {connect} from "react-redux";
import {fetchUser} from "../actions";
import history from "../utils/browserHistory"
import LoginScreen from "./login/LoginScreen";
import ListAll from "./list/ListAll";
import Individual from "./individual/Individual";
import "bootstrap-icons/font/bootstrap-icons.css"
import "./App.css"
import AdminGo from "./admin/AdminGo";
import AdminUsers from "./admin/AdminUsers";
import Alert from "./common/Alert";
import AdminAssignGo from "./admin/AdminAssignGo";
import ForgotPassword from "./password/ForgotPassword";
import ResetPassword from "./password/ResetPassword";

class App extends React.Component {
    componentDidMount() {
        this.props.fetchUser();
    }

    renderAdminRoutes() {
        if (this.props.user.isAdmin) {
            return (
                <>
                    <Route path="/admin/go" component={AdminGo} exact/>
                    <Route path="/admin/go/assign" component={AdminAssignGo} exact/>
                    <Route path="/admin/users" component={AdminUsers} exact/>
                </>
            )
        } else {
            return null
        }
    }

    renderContent() {
        if (this.props.user === null) return null
        if (this.props.user === false) {
            return (
                <Switch>
                    <Route path="/forgot-password" component={ForgotPassword}/>
                    <Route path="/reset-password/:hash" component={ResetPassword}/>
                    <Route component={LoginScreen}/>
                </Switch>
            )
        }
        return (
            <>
                <Header/>
                <Alert/>
                <div className="container" style={{paddingTop: "15px"}}>
                    <Route path="/" component={ListAll} exact/>
                    <Route path="/individualno" component={Individual} exact/>
                    {this.renderAdminRoutes()}
                </div>
            </>
        );

    }

    render() {
        return (
            <Router history={history}>
                {this.renderContent()}
            </Router>
        )
    }
}

function mapStateToProps(state) {
    return {user: state.auth}
}

export default connect(mapStateToProps, {fetchUser})(App);