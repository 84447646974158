import React from "react"
import ForgotPasswordForm from "./ForgotPasswordForm";
import Alert from "../common/Alert";

const ForgotPassword = () => {
    return (
        <div className="container">
            <div className="row justify-content-center mt-5 pt-5">
                <div className="col-12 col-md-8 col-lg-6 pb-5">
                    <ForgotPasswordForm/>
                    <Alert/>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;