import React from "react"
import LoginForm from "./LoginForm";
import Alert from "../common/Alert";

const LoginScreen = () => {
    return (
            <div className="container">
                <div className="row justify-content-center mt-5 pt-5">
                    <div className="col-12 col-md-8 col-lg-6 pb-5">
                        <LoginForm />
                        <Alert/>
                    </div>
                </div>
            </div>
);
}

export default LoginScreen;