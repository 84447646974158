import React from 'react';
import {Link} from 'react-router-dom';

const SurveyForm = () => {
    return (
        <form method="post" autoComplete="on" action="/auth/login">
            <div className="card rounded-0">
                <div className="card-header p-0">
                    <div className="bg-dark text-white text-center py-2">
                        <h3>C Lab GO</h3>
                    </div>
                </div>
                <div className="card-body p-3">
                    <div className="form-group">
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><i className="bi-envelope"/></div>
                            </div>
                            <input type="email" className="form-control" id="inputEmail" placeholder="Email"
                                   name="username" autoComplete="username" required/>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><i className="bi-key"/></div>
                            </div>
                            <input type="password" className="form-control" id="inputPassword" placeholder="Lozinka"
                                   name="password" autoComplete="current-password" required/>
                        </div>
                    </div>

                    <div className="form-group forgot text-right">
                        <Link to="/forgot-password">Zaboravljena lozinka?</Link>
                    </div>

                    <div className="form-group text-center">
                        <input type="submit" name="btn-login" value="Prijava"
                               className="btn btn-info btn-block rounded-0 py-2"/>
                    </div>

                    <div className="form-group text-danger text-center">
                    </div>
                </div>
            </div>
        </form>
    );
};

export default SurveyForm