import React from "react"
import {Link} from "react-router-dom";
import MyDate from "../../utils/MyDate";
import {fetchAllUsers, fetchAssignedGoForYear} from "../../actions";
import {connect} from "react-redux";
import _ from "lodash"
import AssignGoForm from "./form/AssignGoForm";

class AdminAssignGo extends React.Component {
    state = {selectedYear: MyDate.getCurrentDateObject().year}

    componentDidMount() {
        this.props.fetchAllUsers();
        this.props.fetchAssignedGoForYear(this.state.selectedYear);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.selectedYear !== this.state.selectedYear) {
            this.props.fetchAssignedGoForYear(this.state.selectedYear)
        }
    }

    renderYearSelector() {
        return (
            <div className="row bg-dark text-white">
                <div className="col-4 text-end  mt-auto mb-auto">
                    <i className="bi-arrow-left-short" style={{fontSize: "1.5rem", cursor: "pointer"}}
                       onClick={() => this.setState({selectedYear: this.state.selectedYear - 1})}
                    />
                </div>
                <div className="col-4 mt-auto mb-auto fw-bold">
                    {this.state.selectedYear}
                </div>
                {this.renderNextYearPicker()}
            </div>
        );
    }

    render() {
        return (
            <>
                <nav className="nav nav-pills">
                    <Link to="/admin/go" className="nav-item nav-link bg-secondary text-white" href="#">Popis zatraženih
                        GO</Link>
                    <Link to="/admin/go/assign" className="nav-item nav-link active disabled">Postavljanje dodijeljenog
                        GO</Link>
                </nav>
                <div className="row justify-content-center mt-3">
                    <div className="col-12 col-md-3 text-center">
                        {this.renderYearSelector()}
                        <AssignGoForm selectedYear={this.state.selectedYear}/>
                    </div>
                </div>
                {/*<div className="row justify-content-center mt-3">*/}
                {/*    <div className="col-12 col-md-4">*/}
                {/*        <h3 className="text-center">Dodijeljeni GO</h3>*/}
                {/*        <table className="table table-dark">*/}
                {/*            <thead>*/}
                {/*            <tr>*/}
                {/*                <td>Korisnik</td>*/}
                {/*                <td>Dana</td>*/}
                {/*            </tr>*/}
                {/*            </thead>*/}
                {/*            <tbody>*/}
                {/*            {this.renderAssignedGo()}*/}
                {/*            </tbody>*/}
                {/*        </table>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </>
        );
    }

    renderNextYearPicker() {
        if (this.state.selectedYear > MyDate.getCurrentDateObject().year) return null
        return (
            <div className="col-4 text-start mt-auto mb-auto">
                <i className="bi-arrow-right-short" style={{fontSize: "1.5rem", cursor: "pointer"}}
                   onClick={() => this.setState({selectedYear: this.state.selectedYear + 1})}
                />
            </div>
        );
    }
}

export default connect((state) => {
    return {users: state.allUsers, assignedGo: state.assignedGo}
}, {fetchAllUsers, fetchAssignedGoForYear})(AdminAssignGo);