import {FETCH_GO_USERINFO} from "../actions/types";

const userGoReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_GO_USERINFO:
            return action.payload;
        default:
            return state;
    }
}

export default userGoReducer;