import React from 'react';
import axios from "axios";
import browserHistory from "../../utils/browserHistory";

class ResetPasswordForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {password: "", password2: ""}
        this.hash = props.hash;

        this.setPassword = this.setPassword.bind(this);
        this.setPassword2 = this.setPassword2.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    setPassword(event) {
        this.setState({password: event.target.value})
    }

    setPassword2(event) {
        this.setState({password2: event.target.value})
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.password.length < 8) {
            window.alert("Lozinka mora imati najmanje 8 znakova.")
        } else if (this.state.password !== this.state.password2) {
            window.alert("Lozinke se ne podudaraju.")
        } else {
            axios.post("/auth/reset-password", {hash: this.hash, password: this.state.password})
                .then(() => browserHistory.push("/"))
                .catch(() => window.alert("Greška u komunikaciji sa serverom. Pokušajte ponovno."))
        }

    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <input type="hidden" value={this.hash} name="hash" id="hash"/>
                <div className="card rounded-0">
                    <div className="card-header p-0">
                        <div className="bg-dark text-white text-center py-2">
                            <h3>C Lab GO</h3>
                        </div>
                    </div>
                    <div className="card-body p-3">
                        <div className="form-group">
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="bi-key"/></div>
                                </div>
                                <input type="password" className="form-control" id="inputPassword" placeholder="Lozinka"
                                       name="password" required value={this.state.password}
                                       onChange={this.setPassword}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text"><i className="bi-key"/></div>
                                </div>
                                <input type="password" className="form-control" id="inputPassword2"
                                       placeholder="Potvrda lozinke"
                                       name="password2" required value={this.state.password2}
                                       onChange={this.setPassword2}/>
                            </div>
                        </div>

                        <div className="form-group text-center">
                            <input type="submit" name="btn-login" value="Potvrdi"
                                   className="btn btn-info btn-block rounded-0 py-2"/>
                        </div>

                        <div className="form-group text-danger text-center">
                        </div>
                    </div>
                </div>
            </form>
        );
    }
};

export default ResetPasswordForm