import React from "react"
import MonthSelector from "../common/MonthSelector";
import MyDate from "../../utils/MyDate";
import {connect} from "react-redux";
import {fetchActiveUsers, toggleGodisnji, selectMonth} from "../../actions";
import DateCell from "../common/DateCell";

class CalendarPicker extends React.Component {
    componentDidMount() {
        this.props.selectMonth(MyDate.fromObject(this.props.calendar))
    }

    renderMonth(offset) {
        if (!this.props.calendar) return null
        let date = new MyDate(this.props.calendar.year, this.props.calendar.month + offset)

        const className = offset !== 0 ? "d-none d-lg-block" : ""
        return (
            <>
                <div className={`table-responsive col-12 col-lg-4 ms-auto me-auto ${className}`}>
                    <MonthSelector wide={false} offset={offset}/>
                    <table className="table table-bordered table-dark table-sm text-center">
                        <thead>
                        <tr>
                            <td>Pon</td>
                            <td>Uto</td>
                            <td>Sri</td>
                            <td>Čet</td>
                            <td>Pet</td>
                            <td>Sub</td>
                            <td>Ned</td>
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderRows(date)}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    renderRows(date) {
        const daysInMonth = date.getDaysInMonth()
        let firstDay = date.getDayOfWeek();
        const rows = []
        for (let i = 0; i <= 35; i = i + 7) {
            const columns = []
            for (let j = 1; j <= 7; j++) {
                let field = i + j;
                let dayInMonth = field - firstDay + 1
                if (dayInMonth <= 0 || dayInMonth > daysInMonth) {
                    columns.push(<td key={field} className="bg-secondary">&nbsp;</td>)
                } else {
                    date.setDay(dayInMonth)
                    const tmpDate = MyDate.fromObject({...date.getObject(), day: date.getDay()})

                    columns.push(
                        <DateCell
                            userId={this.props.auth.id}
                            clickable={true}
                            date={tmpDate}
                            key={field}
                        />
                    )
                }
            }
            rows.push(<tr key={i}>{columns}</tr>)
        }
        return rows
    }

    render() {
        return (
            <div className="row">
                {this.renderMonth(-1)}
                {this.renderMonth(0)}
                {this.renderMonth(1)}
            </div>
        );
    }
}

export default connect((state) => {
    return {users: state.users, calendar: state.calendar, godisnji: state.godisnji, auth: state.auth}
}, {fetchActiveUsers,toggleGodisnji, selectMonth})(CalendarPicker);