import {FETCH_ASSIGNED_GO} from "../actions/types";
import _ from "lodash";

const transformPayload = payload => {
    const result = {}
    payload.forEach(e => {
        _.merge(result, ({
            [e.year]: {
                [e.userId]: {
                    assigned: e.assignedGo
                }
            }
        }))
    })
    return result
}

const assignedGoReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_ASSIGNED_GO:
            if (!Array.isArray(action.payload)) return state
            const newState = {...state}
            _.merge(newState, transformPayload(action.payload))
            return newState;
        default:
            return state;
    }
}

export default assignedGoReducer