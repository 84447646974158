import React from "react"
import Alert from "../common/Alert";
import ResetPasswordForm from "./ResetPasswordForm";

const ResetPassword = (props) => {
    return (
        <div className="container">
            <div className="row justify-content-center mt-5 pt-5">
                <div className="col-12 col-md-8 col-lg-6 pb-5">
                    <ResetPasswordForm hash={props.match.params.hash}/>
                    <Alert/>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;