import React from "react";
import _ from "lodash";
import {connect} from "react-redux";
import {toggleGodisnji} from "../../actions";

const DateCell = ({date, godisnji, toggleGodisnji, userGo, userId, clickable = false}) => {
    const {year, month, day} = date.getObject();
    const dateInfo = _.get(godisnji, `${year}.${month}.${day}`);
    const title = dateInfo?.praznik?.naziv || ""
    const isWeekend = date.getDayOfWeek() === 6 || date.getDayOfWeek() === 7;

    let onClick = undefined;
    let className;

    if (dateInfo !== undefined && dateInfo.praznik !== undefined) {
        if (isWeekend) {
            className = "bg-praznik-vikend"
        } else {
            className = "bg-praznik-radni"
        }
    } else if (dateInfo !== undefined && dateInfo[userId] !== undefined && dateInfo[userId].id) {
        if (date.isDateInFuture()) {
            if (dateInfo[userId].odobreno) {
                className = "bg-odobreno"
            } else {
                className = "bg-najavljeno" + (clickable ? " pointer" : "")
                if (clickable)
                    onClick = () => toggleGodisnji(year, month, day)
            }
        } else {
            className = "bg-iskoristeno"
        }
    } else if (isWeekend) {
        className = "bg-vikend"
    } else if (date.isDateInFuture()) {
        if (userGo.noviGoPreostalo <= 0 && (userGo.stariGoPreostalo === undefined || userGo.stariGoPreostalo <= 0)) {
            className = "bg-radni-buducnost"
        } else {
            if (clickable)
                onClick = () => toggleGodisnji(year, month, day)
            className = "bg-radni-buducnost" + (clickable ? " pointer" : "")
        }
    } else {
        className = "bg-radni-proslost"
    }

    return (
        <td data-toggle="tooltip" title={title} className={className}
            onClick={onClick}>{day}</td>
    );
}

export default connect((state) => {
    return {godisnji: state.godisnji, userGo: state.userGo}
}, {toggleGodisnji})(DateCell);
