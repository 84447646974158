import {combineReducers} from "redux";
import authReducer from "./authReducer";
import selectedMonthReducer from "./selectedMonthReducer";
import userGoReducer from "./userGoReducer";
import usersReducer from "./usersReducer";
import godisnjiReducer from "./godisnjiReducer";
import adminUserReducer from "./adminUserReducer";
import adminGoReducer from "./adminGoReducer";
import errorReducer from "./errorReducer";
import assignedGoReducer from "./assignedGoReducer";

export default combineReducers({
    auth: authReducer,
    calendar: selectedMonthReducer,
    userGo: userGoReducer,
    users: usersReducer,
    godisnji: godisnjiReducer,
    allUsers: adminUserReducer,
    futureGo: adminGoReducer,
    alert: errorReducer,
    assignedGo: assignedGoReducer
})