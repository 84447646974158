import React from "react"
import {fetchGoUserInfo} from "../../actions";
import {connect} from "react-redux"
import MyDate from "../../utils/MyDate";

class UserGoStatus extends React.Component {

    componentDidMount() {
        this.props.fetchGoUserInfo()
    }

    renderForDesktop = () => {
        const userGo = this.props.userGo;
        if (userGo) {
            const render = []
            render.push(this.renderDesktopRow("Novi", userGo.noviGoUkupno, userGo.noviGoIskoristeno, userGo.noviGoNajavljeno, userGo.noviGoPreostalo))
            if (new MyDate().getMonth() < 7) {
                render.push(this.renderDesktopRow("Stari", userGo.stariGoUkupno, userGo.stariGoIskoristeno, userGo.stariGoNajavljeno, userGo.stariGoPreostalo))
            }
            return render
        }
    }

    renderForMobile = () => {
        const showStari = new MyDate().getMonth() < 7;
        const userGo = this.props.userGo;
        if (userGo) {
            const render = []
            render.push(this.renderMobileRow("Ukupno", userGo.stariGoUkupno, userGo.noviGoUkupno, showStari));
            render.push(this.renderMobileRow("Iskorišteno", userGo.stariGoIskoristeno, userGo.noviGoIskoristeno, showStari));
            render.push(this.renderMobileRow("Najavljeno", userGo.stariGoNajavljeno, userGo.noviGoNajavljeno, showStari));
            render.push(this.renderMobileRow("Preostalo", userGo.stariGoPreostalo, userGo.noviGoPreostalo, showStari));
            return render
        }
    }

    renderMobileRow = (naziv, stari, novi, showStari) => {
        return (
            <tr key={naziv}>
                <td>{naziv}</td>
                {showStari?<td className="bg-secondary">
                    {stari}
                </td>:null}
                <td className="bg-secondary">
                    {novi}
                </td>
            </tr>
        );
    }

    renderDesktopRow = (naziv, ukupno, iskoristeno, najavljeno, preostalo) => {
        return (
            <tr key={naziv}>
                <td>{naziv}</td>
                <td className="bg-secondary">
                    {ukupno}
                </td>
                <td className="bg-secondary">
                    {iskoristeno}
                </td>
                <td className="bg-secondary">
                    {najavljeno}
                </td>
                <td className="bg-secondary">
                    {preostalo}
                </td>
            </tr>
        );
    }

    render() {
        return (
            <div className="row justify-content-center">
                <div className="d-none d-md-block col-12 col-md-8 col-lg-6">
                    <table className="table table-bordered table-dark text-center table-sm">
                        <thead>
                        <tr>
                            <td>GO</td>
                            <td>Ukupno</td>
                            <td>Iskorišteno</td>
                            <td>Najavljeno</td>
                            <td>Preostalo</td>
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderForDesktop()}
                        </tbody>
                    </table>
                </div>
                <div className="d-md-none col-12 col-md-8 col-lg-6">
                    <table className="table table-bordered table-dark text-center table-sm">
                        <thead>
                        <tr>
                            <td>GO</td>
                            {new MyDate().getMonth() < 7 ? <td>Stari</td> : null}
                            <td>Novi</td>
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderForMobile()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default connect((state) => {
    return {userGo: state.userGo}
}, {fetchGoUserInfo})(UserGoStatus);