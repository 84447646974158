import React from "react"
import {connect} from "react-redux"
import {toggleGodisnji} from "../../actions";

const NajavljeniGo = props => {

    const odobreni = []
    const cekaju = []
    props.userGo.najavljeni?.forEach(n => {
            if (n.odobreno) {
                odobreni.push(n)
            } else {
                cekaju.push(n)
            }
        }
    )
    const redova = Math.max(odobreni.length, cekaju.length)
    if (redova === 0) return null
    const redovi = []
    for (let i=0; i<redova; i++) {
        const o = odobreni[i] ? <td className="text-center">{`${odobreni[i].dan}.${odobreni[i].mjesec}.${odobreni[i].godina}.`}</td> : <td>&nbsp;</td>
        const c = cekaju[i] ? <td className="text-center">{`${cekaju[i].dan}.${cekaju[i].mjesec}.${cekaju[i].godina}.`}
            <button type="button" className="btn float-end m-0 p-0" onClick={() => {
                props.toggleGodisnji(cekaju[i].godina,cekaju[i].mjesec,cekaju[i].dan)
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-x-square btn-outline-danger" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                    <path fill-rule="evenodd"
                          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
            </button>
        </td> : <td>&nbsp;</td>
        redovi.push(<tr>{o}{c}</tr>)
    }
    return (
        <div className="col-12 col-md-6 ms-auto me-auto">
            <div className="col-12  bg-dark text-white text-center">Vaši najavljeni GO</div>
            <table className="table table-bordered table-dark">
                <thead>
                <tr>
                    <td className="bg-odobreno text-white text-center col-6">Odobreni</td>
                    <td className="bg-najavljeno text-white text-center col-6">Čekaju Odobrenje</td>
                </tr>
                </thead>
                <tbody>
                {redovi}
                </tbody>
            </table>
        </div>
    );
}

export default connect((state) => {
    return {userGo: state.userGo}
},{toggleGodisnji})(NajavljeniGo)