import React from "react"

const Legend = () => {
    return (
            <div className="row justify-content-center align-items-center text-center text-white">
                <div className="col-10 col-md-2 bg-odobreno">Odobreno</div>
                <div className="col-10 col-md-2 bg-najavljeno">Najavljeno</div>
                <div className="col-10 col-md-2 bg-iskoristeno">Iskorišteno</div>
                <div className="col-10 col-md-2 bg-praznik-radni">Praznik</div>
                <div className="col-10 col-md-2 bg-vikend">Vikend</div>
            </div>
    );
}

export default Legend;