import React from "react"
import {connect} from "react-redux"
import MyDate from "../../utils/MyDate";

class InfoStatus extends React.Component {
    state = {currentTime: MyDate.getCurrentTimeString(), intervalId: null}

    componentDidMount() {
        let intervalId = setInterval(() => {
            this.setState({currentTime: MyDate.getCurrentTimeString()});
        }, 1000)
        this.setState({intervalId: intervalId})
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId)
    }

    render() {
        return (
            <table className="table table-bordered table-dark text-center table-sm">
                <thead>
                <tr>
                    <th className="col-4">
                        {`${this.props.user.firstName} ${this.props.user.lastName}`}
                    </th>
                    <th className="col-4">
                        {MyDate.getCurrentDateString()}
                    </th>
                    <th className="col-4">
                        {this.state.currentTime}
                    </th>
                </tr>
                </thead>
            </table>
        );
    }
}

export default connect((state) => {
    return {user: state.auth}
})(InfoStatus);