import {useField} from "formik";

const MyTextInput = ({label, ...props}) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);
    return (
        <div className="form-group row">
            <label htmlFor={props.id || props.name} className="col-sm-8 col-form-label text-center">{label}</label>
            <div className="col-sm-4">
                <input className="form-control" {...field} {...props} />
            </div>
            {meta.touched && meta.error ? (
                <div className="error text-danger">{meta.error}</div>
            ) : null}
        </div>
    );
};

export default MyTextInput