import React from "react"
import {selectMonth} from "../../actions";
import {connect} from "react-redux"
import MyDate from "../../utils/MyDate";

const MonthSelector = ({calendar, selectMonth, wide, offset=0}) => {
    const date = MyDate.fromObject(calendar)
    console.log("d:", date.getObject())
    let displayDate;
    if (offset === -1) displayDate = date.getDateLastMonth();
    else if ( offset === 1) displayDate = date.getDateNextMonth();
    else displayDate = date;

    const classArrow = wide ? "col-2 col-md-4 col-lg-5" : "col-4";
    const classMonth = wide ? "col-8 col-md-4 col-lg-2" : "col-4";

    const current = new MyDate();
    const canSelectNext = date.getYear() <= current.getYear() || (current.getYear() + 1 === date.getYear() && date.getMonth() < 6)

    if (!date) return null
    return (
        <table className="table table-dark table-sm text-center mb-0 pb-0">
            <thead>
            <tr>
                <th className={`${classArrow}  text-end`}>
                    <i className="bi-arrow-left-short" style={{fontSize: "1.5rem", cursor: "pointer"}}
                       onClick={() => selectMonth(date.getDateLastMonth())}
                    />
                </th>
                <th className={classMonth}>
                    {`${displayDate.getMonthName()} ${displayDate.getYear()}.`}
                </th>
                {canSelectNext?<th className={`${classArrow}  text-start`}>
                    <i className="bi-arrow-right-short" style={{fontSize: "1.5rem", cursor: "pointer"}}
                       onClick={() => selectMonth(date.getDateNextMonth())}
                    />
                </th>:<th className={`${classArrow}`}/>}
                
            </tr>
            </thead>
        </table>
    );
}

export default connect((state) => {
    return {calendar: state.calendar}
}, {selectMonth})(MonthSelector);