import React from "react"
import {connect} from "react-redux";
import {Link, NavLink} from "react-router-dom";

class Header extends React.Component {

    renderAdminButtons() {
        if (this.props.auth.isAdmin) {
            return (
                <>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/admin/go" activeClassName="active" aria-current="page"
                                 exact>Upravljanje GO</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/admin/users" activeClassName="active"
                                 aria-current="page">Upravljanje Korisnicima</NavLink>
                    </li>
                </>
            )
        } else {
            return null
        }
    }

    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">C Lab GO <sup className="text-warning">beta</sup> </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/" activeClassName="active" aria-current="page"
                                         exact>Lista</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/individualno" activeClassName="active"
                                         aria-current="page">Moj Godišnji</NavLink>
                            </li>
                            {this.renderAdminButtons()}
                            <li className="nav-item">
                                <a className="nav-link" href="/api/logout">Odjava</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

function mapStateToProps({auth}) {
    return {auth};
}

export default connect(mapStateToProps)(Header);