import {FETCH_ALL_USERS, UPDATE_USER} from "../actions/types";

const adminUserReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_ALL_USERS:
            return action.payload;
        case UPDATE_USER:
            return state.map(item => {
                if (item.id !== action.payload.id) return item;
                else return action.payload
            })
        default:
            return state;
    }
}

export default adminUserReducer;