export const FETCH_PRAZNICI = "fetch_praznici";
export const FETCH_USER = "fetch_user"
export const SELECT_MONTH = "select_month"
export const FETCH_GO_USERINFO = "fetch_go_userinfo"
export const FETCH_ACTIVE_USERS = "fetch_active_users"
export const FETCH_GODISNJI = "fetch_godisnji"
export const FETCH_ALL_USERS = "fetch_all_users"
export const UPDATE_USER = "update_user"
export const FETCH_FUTURE_GO = "fetch_future_go"
export const ODOBRI_GO = "odobri_go"
export const OBRISI_GO = "obrisi_go"
export const FETCH_ASSIGNED_GO = "fetch_assigned_go"
export const ERROR = "error"