import React from 'react';
import {Form, Formik} from 'formik';
import MyTextInput from "../../form/MyTextInput";
import {setAssignedGo} from "../../../actions";
import {connect} from "react-redux";
import _ from "lodash"
import * as Yup from "yup"

const AssignGoForm = ({users, assignedGo, setAssignedGo, selectedYear}) => {
    const activeUsers = users.filter(user => user.isActive)
    const formFields = activeUsers.map(user => {
        return {
            name: `${user.firstName} ${user.lastName}`,
            assignedGo: _.get(assignedGo, `${selectedYear}.${user.id}.assigned`, 0),
            id: user.id
        }
    })
    const initialValues = {}
    formFields.forEach(user => {
        _.set(initialValues, `${user.id}`, user.assignedGo)
    })

    let validationSchema = {};
    Object.keys(initialValues).forEach(id => {
        validationSchema = {...validationSchema, [id]: Yup.number().required("Unesite broj").min(1, "Broj dana mora biti veći od 0")}
    })

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            //validationSchema={Yup.object(validationSchema)}
            onSubmit={(values) => {
                let mappedValues = []
                for (const [key,value] of Object.entries(values)) {
                    mappedValues = [...mappedValues, {id: key, assignedGo: value} ]
                }
                setAssignedGo(selectedYear, mappedValues)
            }}
        >
            <Form>
                <table className="table table-dark">
                    {
                        formFields.map(user => {
                            return (
                                <tr>
                                    <td>
                                        <MyTextInput
                                            label={user.name}
                                            name={user.id}
                                            type="text"
                                            placeholder=""
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }
                </table>
                <button className="btn btn-success" type="submit">Spremi</button>
            </Form>
        </Formik>
    );
};

export default connect((state) => {
    return {users: state.allUsers, assignedGo: state.assignedGo}
}, {setAssignedGo})(AssignGoForm)