// https://www.pluralsight.com/guides/centralized-error-handing-with-react-and-redux

import axios from "axios"
import {
    FETCH_ACTIVE_USERS,
    FETCH_ALL_USERS,
    FETCH_ASSIGNED_GO,
    FETCH_FUTURE_GO,
    FETCH_GO_USERINFO,
    FETCH_GODISNJI,
    FETCH_PRAZNICI,
    FETCH_USER,
    OBRISI_GO,
    ODOBRI_GO,
    SELECT_MONTH,
    UPDATE_USER,
    ERROR
} from "./types"

export const fetchAssignedGoForYear = year => async dispatch => {
    const {data} = await axios.get(`/api/admin/go/assigned/${year}`)
    dispatch({type: FETCH_ASSIGNED_GO, payload: data})
}

export const setAssignedGo = (year, values) => async dispatch =>  {
    const {data} = await axios.post(`/api/admin/go/assigned/${year}`, values)
    dispatch({type: FETCH_ASSIGNED_GO, payload: data})
}

const fetchedPraznici = []
const fetchedGodisnji = []

export const toggleGodisnji = (year, month, day) => async dispatch => {
    const {data} = await axios.post("/api/go/toggle", {year, month, day})
    dispatch({type: FETCH_GODISNJI, payload: data})
    dispatch(fetchGoUserInfo())
}

export const fetchUser = () => async dispatch => {
    try {
        const {data} = await axios.get("/api/current_user", {timeout: 5000})
        dispatch({type: FETCH_USER, payload: data})
    } catch (err) {
        dispatch({type: FETCH_USER, payload: false})
        dispatch({type: ERROR, payload: {error: "Greška prilikom dohvaćanja podataka."}})
    }
}

const fetchPraznici = (year) => async dispatch => {
    if (fetchedPraznici.includes(year)) return
    const {data} = await axios.get(`/api/go/praznici/${year}`)
    fetchedPraznici.push(year)
    dispatch({type: FETCH_PRAZNICI, payload: data})
}

export const selectMonth = (myDate) => async dispatch => {
    dispatch({type: SELECT_MONTH, payload: myDate.getObject()})
    dispatch(fetchGodisnjiForMonth(myDate))
    dispatch(fetchPraznici(myDate.getYear()))
    dispatch(fetchGodisnjiForMonth(myDate.getDateLastMonth()))
    dispatch(fetchGodisnjiForMonth(myDate.getDateNextMonth()))
    dispatch(fetchPraznici(myDate.getYear() - 1))
    dispatch(fetchPraznici(myDate.getYear() + 1))
}

export const fetchGoUserInfo = () => async dispatch => {
    const {data} = await axios.get("/api/go/user_info")
    dispatch({type: FETCH_GO_USERINFO, payload: data})
}

export const fetchActiveUsers = () => async dispatch => {
    const {data} = await axios.get(`/api/users/active`)
    dispatch({type: FETCH_ACTIVE_USERS, payload: data})
}

const fetchGodisnjiForMonth = (myDate) => async dispatch => {
    const {year, month} = myDate.getObject()
    if (fetchedGodisnji.filter(e => e.y === year && e.m === month).length > 0) return
    const {data} = await axios.get(`/api/go/all/${year}/${month}`)
    fetchedGodisnji.push({y: year, m: month})
    dispatch({type: FETCH_GODISNJI, payload: data})
}

export const fetchAllUsers = () => async dispatch => {
    const {data} = await axios.get(`/api/admin/users`)
    dispatch({type: FETCH_ALL_USERS, payload: data})
}

export const updateUser = (user) => async dispatch => {
    const {data} = await axios.patch(`/api/admin/users`, user)
    dispatch({type: UPDATE_USER, payload: data})
}

export const fetchFutureGo = () => async dispatch => {
    const {data} = await axios.get(`/api/admin/go`)
    dispatch({type: FETCH_FUTURE_GO, payload: data})
}

export const odobriGodisnji = go => async dispatch => {
    go.odobreno = true
    const {data} = await axios.patch(`/api/admin/go/${go.id}`, go)
    dispatch({type: ODOBRI_GO, payload: data})
}

export const obrisiGodisnji = go => async dispatch => {
    const {data} = await axios.delete(`/api/admin/go/${go.id}`)
    dispatch({type: OBRISI_GO, payload: go})
}