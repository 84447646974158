import {SELECT_MONTH} from "../actions/types";
import MyDate from "../utils/MyDate";

const getDefaultState = () => {
    const now = new MyDate();
    return {year: now.getYear(), month: now.getMonth()}
}

const selectedMonthReducer = (state = getDefaultState(), action) => {
    switch (action.type) {
        case SELECT_MONTH:
            const {year,month} = action.payload
            return {year: year, month: month}
        default:
            return state;
    }
}

export default selectedMonthReducer;