export default class MyDate {
    constructor(...args) {
        switch (args.length) {
            case 0:
                this.date = new Date()
                break
            case 2:
                this.date = new Date(args[0], args[1] - 1, 1, 0, 0, 0, 0)
                break
            case 3:
                this.date = new Date(args[0], args[1] - 1, args[2], 0, 0, 0, 0)
                break
            case 4:
                this.date = new Date(args[0], args[1] - 1, args[2], args[3], 0, 0, 0)
                break
            case 5:
                this.date = new Date(args[0], args[1] - 1, args[2], args[3], args[4], 0, 0)
                break
            case 6:
                this.date = new Date(args[0], args[1] - 1, args[2], args[3], args[4], args[5], 0)
                break
            case 7:
                this.date = new Date(args[0], args[1] - 1, args[2], args[3], args[4], args[5], args[6])
                break
            default:
                return null
        }
    }

    static getCurrentDateString = () => {
        const current = new Date();
        return `${current.getDate()}.${current.getMonth() + 1}.${current.getFullYear()}`
    }

    static getCurrentDateObject = () => {
        const current = new Date();
        return {
            year: current.getFullYear(),
            month: current.getMonth() +1,
            day: current.getDate(),
            hour: current.getHours(),
            minute: current.getMinutes(),
            second: current.getSeconds(),
            millis: current.getMilliseconds()
        };
    }

    static getCurrentTimeString = () => {
        const current = new Date();
        return `${current.getHours()}:${String(current.getMinutes()).padStart(2, '0')}:${String(current.getSeconds()).padStart(2, '0')}`
    }

    static fromObject(obj) {
        if (!obj || !obj.year || !obj.month) return null;
        const {year, month, day = 1, hour = 0, minute = 0, second = 0, millis = 0} = obj
        return new MyDate(year, month, day, hour, minute, second, millis);
    }

    getYear() {
        return this.date.getFullYear()
    }

    getMonth = () => {
        return this.date.getMonth() + 1
    }

    getDay = () => {
        return this.date.getDate()
    }

    getHour = () => {
        return this.date.getHours()
    }

    getMinute = () => {
        return this.date.getMinutes()
    }

    getSecond = () => {
        return this.date.getSeconds()
    }

    getMillis = () => {
        return this.date.getMilliseconds()
    }

    getDaysInMonth = () => {
        return new Date(this.getYear(), this.getMonth(), 0, 0, 0, 0, 0).getDate()
    }

    getDayOfWeek = () => {
        return this.date.getDay() || 7
    }

    getMonthName = () => {
        switch (this.getMonth()) {
            case 1:
                return 'Siječanj';
            case 2:
                return 'Veljača';
            case 3:
                return 'Ožujak';
            case 4:
                return 'Travanj';
            case 5:
                return 'Svibanj';
            case 6:
                return 'Lipanj';
            case 7:
                return 'Srpanj';
            case 8:
                return 'Kolovoz';
            case 9:
                return 'Rujan';
            case 10:
                return 'Listopad';
            case 11:
                return 'Studeni';
            case 12:
                return 'Prosinac';
            default:
                return 'Greška';
        }
    }

    setDay = (day) => {
        this.date.setDate(day)
    }

    getDateLastMonth = () => {
        return new MyDate(this.getYear(), this.getMonth() - 1, this.getDay(), this.getHour(), this.getMinute(), this.getSecond())
    }

    isDateInFuture = () => {
        const now = new Date().setHours(0, 0, 0, 0)
        const cur = new Date(this.getYear(), this.getMonth() - 1, this.getDay(), 0, 0, 0, 0)
        return cur > now
    }

    getDateNextMonth = () => {
        return new MyDate(this.getYear(), this.getMonth() + 1, this.getDay(), this.getHour(), this.getMinute(), this.getSecond())
    }

    getObject = () => {
        return {
            year: this.getYear(),
            month: this.getMonth(),
            day: this.getDay(),
            hour: this.getHour(),
            minute: this.getMinute(),
            second: this.getSecond(),
            millis: this.getMillis()
        }
    }

}