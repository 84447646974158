import React from "react"
import {fetchAllUsers, updateUser} from "../../actions";
import {connect} from "react-redux"
import ActionNo from "../common/ActionNo";
import ActionYes from "../common/ActionYes";

class AdminUsers extends React.Component {
    state = {selectedRow: null}

    componentDidMount() {
        this.props.fetchAllUsers()
    }

    renderYesNo(user) {
        const onClick = () => {
            if (window.confirm(`${user.isActive ? "Deaktivirati" : "Aktivirati"} korisnika ${user.firstName} ${user.lastName}?`))
                this.props.updateUser({...user, isActive: !user.isActive})
        }
        return user.isActive ? <ActionYes onClick={onClick}/> : <ActionNo onClick={onClick}/>
    }

    renderUsers(isActive) {
        if (!this.props.users) return null;
        return this.props.users.map(u => {
            if (!u.isVerified) return null
            if (u.isActive !== isActive) return null
            const className = this.state.selectedRow === u.id ? "opaque" : "";
            return (
                <tr key={u.id} onMouseEnter={() => this.setState({selectedRow: u.id})}
                    onMouseLeave={() => this.setState({selectedRow: null})}>
                    <td className={className}>{u.firstName} {u.lastName}</td>
                    <td className={className}>{u.email}</td>
                    {/*<td className={className}>*/}
                    {/*    {this.renderYesNo(u.isAdmin)}*/}
                    {/*</td>*/}
                    <td className={className}>
                        {this.renderYesNo(u)}
                    </td>
                </tr>
            )
        })
    }

    render() {
        return (
            <>
                <div className="row justify-content-center">
                    <button className="btn btn-primary float-start col-6 col-md-2" disabled>Dodaj Korisnika</button>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <h3 className="text-center">Aktivni Korisnici</h3>
                        <table className="table table-dark">
                            <thead>
                            <tr>
                                <td>Korisnik</td>
                                <td>E-mail</td>
                                <td>Aktivan</td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderUsers(true)}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-12 col-md-6">
                        <h3 className="text-center">Deaktivirani Korisnici</h3>
                        <table className="table table-dark">
                            <thead>
                            <tr>
                                <td>Korisnik</td>
                                <td>E-mail</td>
                                <td>Aktivan</td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderUsers(false)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}

export default connect((state) => {
    return {users: state.allUsers}
}, {fetchAllUsers, updateUser})(AdminUsers);