import React from "react"
import {fetchAllUsers, fetchFutureGo, obrisiGodisnji, odobriGodisnji} from "../../actions";
import {connect} from "react-redux"
import ActionYes from "../common/ActionYes";
import ActionNo from "../common/ActionNo";
import {Link} from "react-router-dom";

class AdminUsers extends React.Component {
    state = {selectedRow: null}

    componentDidMount() {
        this.props.fetchAllUsers()
        this.props.fetchFutureGo()
    }

    renderYesNo(u, go) {
        const onOdobri = () => this.props.odobriGodisnji(go)
        const onObrisi = () => {
            if (window.confirm(
                `Želite ${go.odobreno ? "obrisati" : "odbiti"} GO korisnika ${u.firstName} ${u.lastName}?`)) {
                this.props.obrisiGodisnji(go)
            }
        }
        if (go.odobreno) {
            return <ActionNo onClick={onObrisi}/>
        } else {
            return <><ActionYes onClick={onOdobri}/><ActionNo onClick={onObrisi}/></>
        }
    }

    renderUsers(odobreno) {
        if (!this.props.users || !this.props.go) return null;
        return this.props.go.slice().sort(((p1, p2) => {
            if (p1 === null) return -1
            if (p2 === null) return 1
            const d1 = new Date(p1.godina, p1.mjesec - 1, p1.dan)
            const d2 = new Date(p2.godina, p2.mjesec - 1, p2.dan)
            if (d1 < d2) return -1
            else if (d1 > d2) return 1
            else return 0
        })).map(go => {
            if (go.odobreno !== odobreno) return null
            const u = this.props.users.find(u => u.id === go.userId)
            if (!u) return null
            const key = `${u.id}.${go.dan}.${go.mjesec}.${go.godina}`
            const className = this.state.selectedRow === key ? "opaque" : "";
            return (
                <tr key={key} onMouseEnter={() => this.setState({selectedRow: key})}
                    onMouseLeave={() => this.setState({selectedRow: null})}>
                    <td className={className}>{u.firstName} {u.lastName}</td>
                    <td className={className}>{go.dan}.{go.mjesec}.{go.godina}</td>
                    {/*<td className={className}>*/}
                    {/*    {this.renderYesNo(u.isAdmin)}*/}
                    {/*</td>*/}
                    <td className={className}>
                        {this.renderYesNo(u, go)}
                    </td>
                </tr>
            )
        })
    }

    render() {
        return (
            <>
                <nav className="nav nav-pills">
                    <Link to="/admin/go"  className="nav-item nav-link active disabled" href="#">Popis zatraženih GO</Link>
                    <Link to="/admin/go/assign" className="nav-item nav-link bg-secondary text-white">Postavljanje dodijeljenog GO</Link>
                </nav>
                <div className="row mt-3">
                    <div className="col-12 col-md-6">
                        <h3 className="text-center">Zatraženi GO</h3>
                        <table className="table table-dark">
                            <thead>
                            <tr>
                                <td>Korisnik</td>
                                <td>Datum</td>
                                <td>Odobri</td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderUsers(false)}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-12 col-md-6">
                        <h3 className="text-center">Odobreni GO</h3>
                        <table className="table table-dark">
                            <thead>
                            <tr>
                                <td>Korisnik</td>
                                <td>Datum</td>
                                <td>Otkaži</td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderUsers(true)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}

export default connect((state) => {
    return {users: state.allUsers, go: state.futureGo}
}, {fetchAllUsers, fetchFutureGo, odobriGodisnji, obrisiGodisnji})(AdminUsers);