import {FETCH_GODISNJI, FETCH_PRAZNICI} from "../actions/types";
import _ from "lodash";

const godisnjiReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_GODISNJI: {
            if (!Array.isArray(action.payload)) return state
            const newGodisnji = {}
            action.payload.forEach(e => {
                _.merge(newGodisnji, ({
                    [e.godina]: {
                        [e.mjesec]: {
                            [e.dan]: {
                                [e.userId]: {
                                    id: e.id,
                                    odobreno: e.odobreno
                                }
                            }
                        }
                    }
                }))
            })
            const newState = {...state}
            _.merge(newState, newGodisnji)
            return newState;
        }
        case FETCH_PRAZNICI: {
            if (!Array.isArray(action.payload)) return state
            let newPraznici = {}
            action.payload.forEach(e => _.merge(newPraznici, ({
                [e.godina]: {
                    [e.mjesec]: {
                        [e.dan]: {
                            praznik: {
                                naziv: e.naziv
                            }
                        }
                    }
                }
            })))
            const newState = {...state}
            _.merge(newState, newPraznici)
            return newState;
        }
        default:
            return state;
    }
}

export default godisnjiReducer;